import { css } from '@emotion/react';
import { Box, Flex, BoxProps, Card, Grid } from 'components/box';
import { ProductInterface } from 'types/types';
import { Heading, Text } from 'components/typography';
import { useIsEarly, useIsTimedOut } from 'hooks/product';
import Button from 'components/button';
import GalleryProduct, {
  prepProductSizes,
} from 'components/_shared/widgets/gallery/product';
import { ItemSizeEnum } from 'components/_shared/widgets/gallery/shared';
import { Link } from 'components/configurable-routing';
import { HomePageHorizontalProgressBar } from 'components/count-down-progress-bar';
import { useIsStaffMember } from 'hooks/customer';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ClientOnly } from 'components/_shared/widgets/render-control';
import QuickAddToCart from 'components/_shared/widgets/quick-add-to-cart';

const LimitedTimeDeal = ({
  item,
  productSide = 'left',
  color: color_ = '#efeff2',
  ...rest
}: {
  item: ProductInterface;
  productSide?: 'left' | 'right';
  color?: string;
} & BoxProps) => {
  const [isHidden, setIsHidden] = useState(false);

  const isTimedOut = useIsTimedOut(item.activeToDate ?? '');
  const isEarly = useIsEarly(item.activeFromDate ?? '');
  const isStaffMember = useIsStaffMember();

  const {
    query: { when: date },
  } = useRouter();

  /**
   * Rendering null based on a timer can drastically change the outcome between
   * a cached version of a page and the initial hydration of that same page.
   * This can lead to the dreaded NextJS server-side/client-side mismatch which often causes layout issues.
   *
   * @see https://github.com/vercel/next.js/discussions/17443
   *
   * So instead we render null based on state that only ever gets updated after a useEffect has run.
   */
  useEffect(() => {
    if (
      isTimedOut ||
      !item.activeFromDate ||
      !item.activeToDate ||
      (isEarly && (!isStaffMember || !date))
    ) {
      setIsHidden(true);
    }
  }, [
    isTimedOut,
    item.activeFromDate,
    item.activeToDate,
    isEarly,
    isStaffMember,
    date,
  ]);

  if (isHidden) {
    return null;
  }

  const linkProps = {
    dynamicUrl: '/products/[id]',
    href: `/products/${item.id}${date ? `/?when=${date}` : ''}`,
  };

  return (
    <Card
      {...rest}
      borderRadius={3}
      width="100%"
      mb={[3, 0]}
      css={theme => css`
        @media ${theme.mediaQueries.mobileOnly} {
          box-shadow: none;
          border: unset;
          padding: 0;
        }
      `}
    >
      <Grid
        gridAutoFlow="row"
        gridColumnGap={4}
        gridRowGap={[0, 3]}
        gridTemplateColumns={['1fr', '1fr 1fr']}
        gridTemplateAreas={[
          `'title' 'image' 'details'`,
          `'image title' 'image details' 'image button'`,
        ]}
        alignItems="start"
        mb={0}
      >
        <Box gridArea="title">
          <Flex justifyContent="center">
            <Text
              color="black"
              fontSize={['1.7rem', 'xl', '2.8rem']}
              mt={[0, 1, 5]}
              fontWeight="900"
              fontFamily="header"
              css={css`
                text-transform: uppercase;
              `}
            >
              {item.campaign?.name}
            </Text>
          </Flex>
          <ClientOnly>
            <HomePageHorizontalProgressBar
              from={item.activeFromDate || ''}
              to={item.activeToDate || ''}
            />
          </ClientOnly>
        </Box>

        <Box gridArea="image">
          <Link {...linkProps}>
            <GalleryProduct
              product={item}
              sizes={prepProductSizes(
                [
                  ItemSizeEnum.extraLarge,
                  ItemSizeEnum.large,
                  ItemSizeEnum.large,
                ],
                [1, 2, 2]
              )}
              isAccented={[true, true, true]}
              isLimitedTimeDeal
              hideDetails
              noLink
            />
          </Link>
        </Box>

        <Box gridArea="details">
          <Link {...linkProps}>
            <Text
              mt={[3, 1]}
              mb={0}
              color="black"
              fontSize={['0.9rem', '1.5rem', '1.6rem']}
              fontWeight="bold"
              fontFamily="header"
              textAlign={[null, 'center']}
              css={theme => css`
                width: 70%;
                @media ${theme.mediaQueries.tabletUp} {
                  width: 100%;
                }
              `}
            >
              {item.brand}
            </Text>

            <Text
              mt={[1, 2]}
              mb={2}
              fontFamily="header"
              fontWeight={500}
              color="darkGrey"
              fontSize={['s', '1rem', '1.25rem']}
              lineHeight="1.25em"
              textAlign={[null, 'center']}
              css={theme => css`
                width: 70%;
                @media ${theme.mediaQueries.tabletUp} {
                  width: 100%;
                }
              `}
            >
              {item.shortName}
            </Text>
          </Link>

          <Grid
            className="show-for-mobile-only"
            gridTemplateColumns="1fr auto"
            alignItems="end"
            justifyContent="space-between"
            gridColumnGap={0}
            gridRowGap={0}
            mb={2}
            css={css`
              overflow: visible;
            `}
          >
            <Flex alignItems="flex-end" flexWrap="wrap">
              {item.hasPriceRange && (
                <Heading
                  fontSize={[1, 2]}
                  color="black"
                  className="highlightOnHover"
                  fontWeight={700}
                  mr={1}
                >
                  From
                </Heading>
              )}

              <Heading
                fontSize={5}
                fontWeight={600}
                mr={2}
                color="black"
                className="highlightOnHover"
                css={css`
                  position: relative;
                  bottom: -0.05em;
                `}
              >
                {item.hasPriceRange
                  ? item.minPrice.formattedValue
                  : item.price.formattedValue}
              </Heading>

              {item.retailPrice.value > 0 && (
                <Heading
                  fontSize={2}
                  color="darkGrey"
                  fontWeight={500}
                  mt={2}
                  css={css`
                    text-decoration: line-through;
                  `}
                >
                  {item.retailPrice.formattedValue}
                </Heading>
              )}
            </Flex>

            <QuickAddToCart product={item} />
          </Grid>

          <Box mt={4} className="hide-for-mobile-only">
            {item.retailPrice.value > 0 && (
              <Text
                ml={1}
                fontWeight={500}
                color="black"
                fontFamily="header"
                fontSize={['r', null, 'm']}
                textAlign="center"
                css={css`
                  text-decoration: line-through;
                `}
              >
                {item.retailPrice.formattedValue}
              </Text>
            )}

            <Flex mt={2} justifyContent="center" alignItems="baseline">
              {item.hasPriceRange && <Text color="black">From</Text>}
              <Text
                fontFamily="header"
                fontSize={['l', null, 'x2l']}
                color="black"
                fontWeight={700}
              >
                {item.hasPriceRange
                  ? item.minPrice.formattedValue
                  : item.price.formattedValue}
              </Text>
            </Flex>
          </Box>
        </Box>

        <Flex
          gridArea="button"
          className="hide-for-mobile-only"
          justifyContent="center"
          alignItems="center"
          mt={[null, 1]}
          mb={[null, 4, 5]}
          css={css`
            gap: 12px;
          `}
        >
          <Link {...linkProps} width="75%">
            <Button
              width="100%"
              size="medium"
              fontSize={[1, 2]}
              shadow
              py={2}
              fontColor="white"
              disabled={item.isSoldOut}
            >
              View deal
            </Button>
          </Link>

          <QuickAddToCart product={item} />
        </Flex>
      </Grid>
    </Card>
  );
};

export default LimitedTimeDeal;
