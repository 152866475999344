import { useCountdown } from 'hooks/product';
import { Flex } from 'components/box';
import { Text } from 'components/typography';
import { css } from '@emotion/react';
import ClockIcon from 'icons/lunchtime-clock.svg';
import { describeLine, secToStr } from '../../util/limited-time-campaign';

const STROKE_WIDTH = 15;
const SPACING = STROKE_WIDTH / 2;
const WIDTH = 300;

const ProgressBar = ({
  percentage,
  clockColor,
}: {
  percentage: number;
  clockColor: string;
}) => (
  <svg
    css={css`
      width: 100%;
      height: 100%;

      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.12));
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.12));
    `}
    viewBox={`0 0 ${WIDTH} ${STROKE_WIDTH}`}
    enableBackground="false"
  >
    <path
      d={describeLine(WIDTH, SPACING, 100)}
      fill="none"
      stroke="#eff1f3"
      strokeWidth={STROKE_WIDTH}
      strokeLinecap="round"
    />
    <path
      d={describeLine(WIDTH, SPACING, percentage)}
      fill="none"
      stroke={clockColor}
      strokeWidth={STROKE_WIDTH}
      strokeLinecap="round"
    />
  </svg>
);

export const ProductDetailsHorizontalProgressBar = ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  const { clockColor, secRemaining, percentage } = useCountdown(from, to);

  return (
    <Flex alignItems="center" my={3}>
      <ClockIcon
        css={theme => css`
          fill: ${clockColor};
          margin-right: 10px;
          width: 25px;
          height: 25px;
          @media ${theme.mediaQueries.mobileOnly} {
            width: 20px;
            height: 20px;
          }
        `}
      />
      <ProgressBar clockColor={clockColor} percentage={percentage} />
      <Text
        textAlign="center"
        display="block"
        fontSize={['r', 'xl', 'l']}
        fontWeight="bold"
        fontFamily="header"
        css={theme => css`
          @media ${theme.mediaQueries.tabletUp} {
            color: ${clockColor};
          }
        `}
        ml={2}
        mt={[0, 1, 0]}
      >
        {secToStr(secRemaining)}
      </Text>
    </Flex>
  );
};

export const HomePageHorizontalProgressBar = ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  const { clockColor, secRemaining, percentage } = useCountdown(from, to);

  return (
    <Flex alignItems="center" flexDirection={['row', 'column']} my={2}>
      <ClockIcon
        css={theme => css`
          fill: ${clockColor};
          margin-right: 10px;
          @media ${theme.mediaQueries.tabletUp} {
            display: none;
          }
        `}
      />
      <ProgressBar percentage={percentage} clockColor={clockColor} />
      <Flex
        alignContent="center"
        css={theme => css`
          @media ${theme.mediaQueries.tabletUp} {
            margin-top: 1rem;
          }
        `}
      >
        <ClockIcon
          css={theme => css`
            fill: ${clockColor};
            @media ${theme.mediaQueries.mobileDown} {
              display: none;
            }
          `}
        />

        <Text
          textAlign="center"
          display="block"
          fontSize={['s', '1rem', 'l']}
          fontWeight="bold"
          fontFamily="header"
          color="black"
          ml={1}
          mt={[0, 1, 0]}
        >
          {secToStr(secRemaining)}
        </Text>
      </Flex>
    </Flex>
  );
};
