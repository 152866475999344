export const describeLine = (
  width: number,
  spacing: number,
  percentage: number
): string => {
  return [
    'M',
    spacing.toString(),
    spacing.toString(),
    'H',
    Math.max((percentage / 100) * width - spacing, spacing).toString(),
  ].join(' ');
};

export const secToStr = (sec: number) => {
  const seconds = Math.floor(sec % 60)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor(sec / 60)
    .toString()
    .padStart(2, '0');

  return sec > 0 ? `${minutes}:${seconds}` : '0:00';
};
